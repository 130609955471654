<template>
  <div
    ref="trigger"
    class="pipeline-item"
    :class="{
      open: isOpen,
      'no-info': !item.pipeline_title && !item.pipeline_desc,
    }">
    <div
      class="program-stage"
      @click="open">
      <div class="caret"></div>
      <div class="program-data-mobile">
        <span class="left-text">Program</span>
        <span class="prog-label">{{ item.pipeline_stage }}</span>
      </div>
      <div class="program font-smaller">
        <div
          class="program-content-wrapper"
          v-html="item.pipeline_program"></div>
      </div>
      <div class="stages">
        <div class="stage"></div>
        <div class="stage"></div>
        <div class="stage"></div>
        <div class="stage"></div>
        <div class="stage"></div>
        <div
          ref="stage"
          class="stage-progress"
          :class="item.pipeline_type.value"></div>
      </div>
    </div>
    <Vue3SlideUpDown v-model="isOpen">
      <div class="program-info">
        <div class="divider" />
        <div class="program-info-container">
          <div
            class="pipeline-title h6"
            v-html="item.pipeline_title"></div>
          <div
            class="pipeline-desc font-smaller"
            v-html="item.pipeline_desc"></div>
        </div>
      </div>
    </Vue3SlideUpDown>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { Vue3SlideUpDown } from 'vue3-slide-up-down'
import { gsap } from 'gsap'

const isOpen = ref(false)

const open = () => {
  isOpen.value = !isOpen.value
}

const props = defineProps(['item'])

const trigger = ref(null)
const stage = ref(null)

let tl

const destroyTl = () => {
  tl.kill(true)
}

onMounted(() => {
  tl = gsap.timeline({
    onComplete: destroyTl,
    scrollTrigger: {
      trigger: trigger.value,
      start: 'top+=200 bottom',
    },
  })

  tl.to(stage.value, {
    width: `${props.item.pipeline_stage_progress}%`,
    duration: 0.5,
  })
})

onBeforeUnmount(() => {
  if (tl) {
    tl.kill(true)
  }
})
</script>

<style lang="scss">
.pipeline-item {
  position: relative;
  background-color: $white;
  @include transition;

  @include mobile {
    border-radius: 20px;
    border: 1px solid #000;
    // padding: 10px 20px 25px 20px;
  }

  &.open {
    @media (min-width: 768px) {
      background-color: $light-gray;
    }

    .program-stage {
      .caret:after {
        top: -1px;
        transform: scale(-1);
      }
    }
  }

  &.no-info {
    pointer-events: none;

    .program-stage .caret {
      display: none;
    }
  }

  .program-stage {
    cursor: pointer;
    position: relative;
    display: flex;

    @include mobile {
      flex-direction: column;
      padding: 11px 20px 25px 20px;
    }

    .caret {
      position: absolute;
      top: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      margin: auto;
      border-radius: 50%;
      background-color: $black;

      @media (min-width: 768px) and (max-width: 1024px) {
        left: 0px;
        transform: scale(0.75);
      }

      &:after {
        content: '';
        position: relative;
        display: block;
        top: 0;
        width: 16px;
        height: 9px;
        background-image: url('@/assets/img/caret-down-white.svg');
        background-repeat: no-repeat;
        background-size: contain;
        transform: scale(1);
        @include transition;
      }
      @include mobile {
        right: 8px;
        top: 7px;
        margin: 0px;
      }
      @media (min-width: 1024px) {
        left: 2px;
      }
    }

    .program-data-mobile {
      display: none;

      @include mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 25px;
        margin-left: 8px;
      }

      @media (min-width: 450px) {
        padding-right: 60px;
      }

      .left-text {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.308px;
      }
      .prog-label {
        border-radius: 100px;
        background: rgba(0, 0, 0, 0.4);
        flex-shrink: 0;
        color: white;
        padding: 0px 15px;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 16px;
      }
    }

    .program {
      width: 22%;
      padding: 12px 0;

      @include mobile {
        padding: 0px;
        width: 100%;
      }

      .program-content-wrapper {
        margin-left: 18%;

        @include mobile {
          margin-left: 8px;
          font-size: 22px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px;
          letter-spacing: -0.484px;
          margin-bottom: 15px;
        }

        > * {
          text-decoration: none !important;
          appearance: none !important;
        }
      }
    }

    .stages {
      position: relative;
      display: flex;
      justify-content: space-between;
      flex: 1;

      .stage {
        width: 20%;
        border-left: 1px solid rgba(0, 0, 0, 0.2);
        @include mobile {
          display: none;
        }
      }

      .stage-progress {
        position: absolute;
        top: 50%;
        left: 0;
        height: 20px;
        border-radius: 300px;
        transform: translateY(-50%);

        &.oncology {
          background: linear-gradient(
            270deg,
            #683dde -25.33%,
            #6889e9 38.64%,
            #67daf4 100%
          );
        }

        &.immunology {
          background: linear-gradient(270deg, #683dde -619.85%, #e8bd50 100%);
        }

        &.neuroscience {
          background: linear-gradient(270deg, #683dde -681.4%, #fa95b0 100%);
        }
      }
    }
  }

  .program-info {
    @apply relative pt-[30px] pb-[30px] lg:py-[40px];
    .divider {
      @apply absolute left-0 top-[5px] w-full h-[1px] bg-black/10 md:hidden;
    }
    .program-info-container {
      @apply flex gap-[30px] flex-col  mx-[28px]
      lg:mx-[4.5%] lg:flex-row;

      .pipeline-title {
        @apply lg:w-[42%];

        > * {
          text-decoration: none !important;
          appearance: none !important;
        }
      }

      .pipeline-desc {
        flex: 1;
      }
    }
  }
}
</style>

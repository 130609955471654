<template>
  <header
    id="header-nav"
    ref="header"
    class="header-nav"
    :class="`${navOpen ? 'nav-open' : ''} ${direction} ${
      scrolling ? 'scrolling' : ''
    }`">
    <nav ref="navWrapper">
      <div class="nav-outer-container">
        <div class="logo">
          <a
            href="/"
            @click="scrollToTop">
            <img
              :src="site.nav_logo.url"
              alt="Eikontx logo" />
          </a>
        </div>
        <div
          v-if="device === 'desktop'"
          class="nav-wrapper"
          :class="`${navOpen ? 'nav-open' : ''} `"
          data-lenis-prevent>
          <ul
            ref="nav"
            class="nav-container">
            <template v-if="introShown">
              <template
                v-for="(item, index) in topLevelMenu(menu)"
                :key="item.id">
                <template v-if="item.parent === '0'">
                  <NavMenuItem
                    :key="item.id"
                    :data-ind="index"
                    :menu="menu"
                    :item="item"
                    @click="close" />
                </template>
              </template>
            </template>
          </ul>
        </div>
        <div
          v-if="
            device !== 'desktop' &&
            !router.currentRoute.value.fullPath.includes('team')
          "
          class="hamburger"
          :class="`${navOpen ? 'nav-open' : ''}`"
          @click="open">
          <div class="dots">
            <span class="blue"></span>
            <span class="orange"></span>
            <span class="pink"></span>
          </div>
          <p>menu</p>
        </div>
        <div class="nav-back">
          <a
            class="btn-back font-smaller"
            href="/#leadership">
            <img
              class="arrow"
              src="@/assets/img/icon-arrow-back.svg"
              alt="Back Arrow" />
            <p>Back</p>
          </a>
        </div>
      </div>
      <Vue3SlideUpDown
        ref="mobileNav"
        v-model="navOpen">
        <div
          v-if="device !== 'desktop'"
          class="mobile-nav-wrapper"
          :class="`${navOpen ? 'nav-open' : ''} `"
          data-lenis-prevent>
          <div class="nav-wrapper">
            <ul
              ref="nav"
              class="nav-container-mobile">
              <template v-if="introShown">
                <transition-group
                  v-for="(item, index) in topLevelMenu(menu)"
                  :key="item.id"
                  appear
                  @before-enter="beforeEnter"
                  @enter="enter">
                  <template v-if="item.parent === '0'">
                    <NavMenuItem
                      :key="item.id"
                      :data-ind="index"
                      :menu="menu"
                      :item="item"
                      @click="close" />
                  </template>
                </transition-group>
              </template>
            </ul>
          </div>
        </div>
      </Vue3SlideUpDown>
    </nav>
  </header>
</template>

<script setup>
import NavMenuItem from './NavMenuItem.vue'
import gsap from 'gsap'
import { ref, onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/stores/main'
import useIntroShown from '@/components/composables/useIntroShown.js'
import useSmoothScroll from '@/components/composables/useSmoothScroll.js'
import useUtils from '@/components/composables/useUtils.js'
import { Vue3SlideUpDown } from 'vue3-slide-up-down'

const router = useRouter()
const store = useStore()
const menu = ref(store.menu({ name: 'main-menu' }))
const site = ref(store.acfOptions())
const header = ref(null)
const navOpen = ref(false)
const direction = ref('up')
const scrollTop = ref(0)
const scrolling = ref(false)
const mobileNav = ref(null)
const { introShown } = useIntroShown()

const { locoScroll } = useSmoothScroll()
const { device } = useUtils()

const topLevelMenu = (menu) => {
  const topMenu = menu.filter((x) => x.parent === '0')
  return topMenu
}

const open = () => {
  if (navOpen.value) {
    navOpen.value = false
    locoScroll.value.start()
  } else {
    navOpen.value = true
    locoScroll.value.stop()
  }
}

const close = () => {
  navOpen.value = false
  locoScroll.value.start()
}

const beforeEnter = (el) => {
  // add the initial style to the elements
  el.style.transform = 'translateY(10px)'
  el.style.opacity = 0
}

const enter = (el) => {
  gsap.to(el, {
    y: 0,
    opacity: 1,
    duration: 0.3,
    delay: el.dataset.ind * 0.3,
  })
}

function handleScroll() {
  const scrollDir = locoScroll.value.direction

  if (scrollDir == -1 || scrollDir == 0) {
    direction.value = 'up'
  } else {
    direction.value = 'down'
  }

  scrollTop.value = window.pageYOffset
  if (scrollTop.value >= 100) {
    scrolling.value = true
  } else {
    direction.value = 'up'
    scrolling.value = false
  }
}

const scrollToTop = () => {
  locoScroll.value.scrollTo('top', {
    immediate: false,
  })
}

onMounted(() => {
  document.addEventListener('scroll', handleScroll, { passive: true })
})

onUnmounted(() => {
  document.removeEventListener('scroll', handleScroll, false)
})
</script>

<style lang="scss">
.header-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 95%;
  max-width: 1000px;
  margin: 20px auto 0;
  z-index: 10;

  @include tablet-landscape(1) {
    top: 0;
  }

  &.compact {
    display: flex;
    align-items: center;
    justify-content: center;

    nav {
      .nav-outer-container {
        width: auto;
        height: 60px;
        @include mobile {
          width: 100%;
        }

        .nav-wrapper {
          display: none;
        }

        .nav-back {
          display: block;
        }
      }
    }
  }

  nav {
    .nav-outer-container {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 7px 20px;
      margin: auto;
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.7);
      backdrop-filter: blur(14.6px);
      max-width: $max-width;

      @include tablet-landscape(1) {
        justify-content: space-between;
      }

      .logo {
        margin-right: 7%;

        a {
          display: block;
        }
      }

      .nav-wrapper {
        flex: 1;

        @include tablet-landscape(1) {
          display: none;
        }

        ul {
          display: flex;
          justify-content: space-between;

          li {
            a {
              position: relative;
              font-size: 1.8rem;
              padding: 10px 0;

              &:hover:after {
                transform: translateX(-50%) scale(1);
              }

              &:after {
                content: '';
                position: absolute;
                left: 50%;
                bottom: 0;
                display: block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                box-shadow:
                  0px 0px 11.6px #67daf4,
                  0px 0px 23.1px #67daf4;
                transform: translateX(-50%) scale(0);
                background: $light-blue;
                @include transition;
              }
            }
          }
        }
      }

      .hamburger {
        display: none;
        position: relative;
        display: flex;
        flex-direction: column;
        top: -2px;

        .dots {
          position: relative;
          width: 100%;
          height: 27px;
          margin: 0 0 7px;

          span {
            position: absolute;
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 50%;

            &.blue {
              left: 50%;
              top: 30%;
              transform: translateX(-50%);
              background: $light-blue;
              box-shadow:
                0px 0px 11.6px $light-blue,
                0px 0px 62.8px $light-blue;
            }

            &.orange {
              bottom: 0;
              left: 0;
              background: $orange;
              box-shadow:
                0px 0px 11.6px $orange,
                0px 0px 62.8px $orange;
            }

            &.pink {
              bottom: 0;
              right: 0;
              background: $pink;
              box-shadow:
                0px 0px 11.6px $pink,
                0px 0px 62.8px $pink;
            }
          }
        }

        p {
          line-height: 1;
          font-size: 11px;
        }
      }

      .nav-back {
        display: none;
        cursor: pointer;
        position: relative;
        margin-left: 50px;

        .btn-back {
          display: flex;

          img {
            margin-right: 10px;
          }
        }
      }
    }
    .slide-up-down__container {
      @apply relative top-2;
      .mobile-nav-wrapper {
        @apply h-[calc(100vh-110px)];
        .nav-wrapper {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 80px 20px;
          margin: auto;
          border-radius: 10px;
          animation: backdrop-filter-animation 1.5s forwards;
          animation-delay: 0.1s;
          max-width: $max-width;
          @apply h-full flex items-start;

          @include tablet-landscape(1) {
            max-height: 100%;
            -webkit-overflow-scrolling: touch;
            overflow: scroll;
          }
          ul {
            @apply flex flex-col text-center w-full;
            li {
              @apply min-h-[70px] flex items-center justify-center border-solid border-b border-white border-opacity-20;
            }
          }
        }
        @keyframes backdrop-filter-animation {
          from {
            backdrop-filter: blur(0px);
            background: rgba(0, 0, 0, 0);
          }

          to {
            backdrop-filter: blur(10px);
            background: rgba(0, 0, 0, 0.7);
          }
        }
      }
    }
  }
}
</style>

<template>
  <div
    id="website-wrapper"
    @click="handleClicks">
    <transition
      name="fade"
      mode="out-in">
      <SiteIntro v-if="!introShown" />
    </transition>
    <NavMenu
      class="main-nav"
      :class="navState.class"
      name="main-menu" />
    <div id="website-content">
      <div class="content-container">
        <router-view v-slot="{ Component }">
          <transition
            mode="out-in"
            @before-enter="transitionElement"
            @enter="pageEnter"
            @after-enter="afterPageEnter"
            @leave="pageLeave"
            @after-leave="completeTransition">
            <component
              :is="Component"
              :key="$route.path" />
          </transition>
        </router-view>
      </div>
      <AppFooter />
    </div>
    <transition name="fade">
      <PlatformModal
        v-if="platformModal.open"
        :data="platformModal.data" />
    </transition>
    <transition name="fade">
      <SiteLoading v-if="loading || transitioning" />
    </transition>
  </div>
</template>

<script setup>
import NavMenu from './components/template-parts/NavMenu/NavMenu.vue'
import AppFooter from './components/template-parts/Footer.vue'
import SiteLoading from './components/utility/SiteLoading.vue'
import SiteIntro from './components/utility/SiteIntro.vue'
import PlatformModal from './components/utility/PlatformModal.vue'
import { ref, computed, provide, onMounted } from 'vue'
import { useStore } from '@/stores/main'
import { useRouter } from 'vue-router'
import emitter from './scripts/emitter'
import useIntroShown from './components/composables/useIntroShown.js'
import gsap from 'gsap'
// Uncomment the following line to enable smoothScroll
import useSmoothScroll from './components/composables/useSmoothScroll.js'

const router = useRouter()
const store = useStore()
const site = ref(store.site)
const showMenu = ref()
const transitioning = ref(false)
const { introShown } = useIntroShown()
const pageLoaded = ref(false)

const navState = ref({
  class: '',
})

const platformModal = ref({
  open: false,
  data: null,
})

provide('navState', navState)
provide('platformModal', platformModal)

// // Uncomment the following line to enable smoothScroll
const { locoScroll } = useSmoothScroll()

const loading = computed(() => {
  return store.site.loading
})

const transitionElement = () => {
  // console.log('beforeEnter')
}
const completeTransition = () => {
  // console.log('afterLeave')
  showMenu.value = false
}

const pageLeave = (el, done) => {
  // console.log('onLeave')
  transitioning.value = true
  gsap.to(el, {
    duration: 0.3,
    opacity: 0,
    onComplete: done,
  })
}

const pageEnter = (el, done) => {
  // console.log('onEnter')
  showMenu.value = true

  gsap.fromTo(
    el,
    {
      opacity: 0,
    },
    {
      duration: 0.3,
      opacity: 1,
      onComplete: done,
    }
  )
}

const afterPageEnter = () => {
  // console.log('afterPageEnter')
  emitter.emit('pageEnter')
  transitioning.value = false
}

const getLinkEl = (el) => {
  while (el.parentNode) {
    if (el.tagName === 'A') return el
    el = el.parentNode
  }
}

const handleClicks = (e) => {
  const a = getLinkEl(e.target)
  if (a && a.href.includes(site.value.url)) {
    const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = e
    // don't handle if has class 'no-router'
    if (a.className.includes('no-router')) return
    // don't handle with control keys
    if (metaKey || altKey || ctrlKey || shiftKey) return
    // don't handle when preventDefault called
    if (defaultPrevented) return
    // don't handle right clicks
    if (button !== undefined && button !== 0) return
    // don't handle if `target="_blank"`
    if (a.target && a.target.includes('_blank')) return
    // don't handle same page links
    const currentURL = new URL(a.href, window.location.href)
    if (currentURL && currentURL.pathname === window.location.pathname) {
      // if same page link has same hash prevent default reload
      if (currentURL.hash === window.location.hash) e.preventDefault()
    }
    // Prevent default and push to vue-router
    e.preventDefault()
    const path = a.href.replace(site.value.url, '')
    router.push(path)
  }
}

const handleScroll = ({ el, offset, duration, immediate, lock }) => {
  if (pageLoaded.value) {
    if (!el) {
      // console.log('scrolling to top')
      locoScroll.value.scrollTo('top', { immediate })
    } else {
      // console.log('scrolling to el', el)
      setTimeout(() => {
        locoScroll.value.scrollTo(el, { offset, duration, immediate, lock })
      }, 100)
    }
  } else {
    setTimeout(() => {
      handleScroll({ el, offset, duration, immediate, lock })
      console.log('retrying scroll...')
    }, 100)
  }
}

onMounted(() => {
  window.onload = () => {
    pageLoaded.value = true
  }
  emitter.on('scrollNow', ({ el, offset, duration, immediate, lock }) => {
    // console.log('scroll event received')
    handleScroll({ el, offset, duration, immediate, lock })
  })
})
</script>

<style lang="scss">
@tailwind base;
@tailwind components;
@tailwind utilities;
// @import '@/assets/styles/reset.scss';
// @import '@/assets/styles/global.scss';

/* Vue transition classes
-------------------------------------------- */

.fade-enter-from {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity 0.4s ease-out;
}
.fade-leave-to {
  opacity: 0;
}
.fade-leave-active {
  transition: opacity 0.4s ease-in;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

#website-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.content-container {
  min-height: 100vh;
}

// Lenis Styles START
html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}
// Lenis Styles END
</style>

<template>
  <section
    id="contact"
    class="contact-form module">
    <div class="container med">
      <div class="contact-form-wrapper">
        <div class="form-container border-radius">
          <div class="form-group">
            <div class="form-group-top">
              <h3 class="form-section-title h5">
                {{ props.data.form_section_title }}
              </h3>
              <div
                class="form-section-text font-smaller editor-formatting"
                v-html="props.data.form_section_content" />
            </div>
            <div class="form-area">
              <FormKit
                v-if="form"
                id="formKit"
                type="form"
                @submit="submitHandler">
                <FormKitSchema
                  :schema="selectedForm"
                  :library="library" />
              </FormKit>
            </div>
          </div>
        </div>
        <div class="contact-content">
          <div class="jobs-callout border-radius">
            <div class="jobs-callout-content">
              <div
                class="headline h5"
                v-html="data.form_section_callout_headline"></div>
              <a
                :href="data.form_section_callout_link.url"
                :target="data.form_section_callout_link.target"
                class="btn trans-black"
                >{{ data.form_section_callout_link.title }}</a
              >
            </div>
          </div>
          <div class="concern-box border-radius">
            <div
              class="concern font-smaller editor-formatting"
              v-html="data.form_section_report_text"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
 {
  "NAME": "ContactFormComponent",
  "CLASSNAME": "contact-form",
  "URL": "http://eikon.local/"
 }
#ENDMETA */
import { ref, computed, onBeforeMount } from 'vue'
import { useStore } from '../../stores/main'
import { FormKitSchema } from '@formkit/vue'
import { reset } from '@formkit/core'
import Axios from 'axios'

const props = defineProps(['data'])
const store = useStore()
const disabled = ref(false)
const message = ref('')
const library = ref({})
const form = ref(null)
const request = ref({
  type: 'forms',
  id: props.data.form_section_id,
})

const selectOptions = (choices) => {
  if (choices) {
    return choices.map((choice) => {
      return choice.text
    })
  } else {
    return []
  }
}

const validationTypes = (required, type) => {
  const validation = []
  if (required) {
    validation.push('required')
  }
  if (type === 'email') {
    validation.push('email')
  }
  if (type === 'phone') {
    validation.push('number')
    validation.push('length:10,10')
  }
  const finalValidation = validation.join('|')
  return finalValidation
}

const selectedForm = computed(() => {
  const schema = []
  form.value.fields.forEach((field) => {
    schema.push({
      $formkit: field.type === 'phone' ? 'tel' : field.type,
      name: field.label,
      // label: field.label,
      placeholder: field.placeholder,
      suffix: '',
      options: selectOptions(field.choices),
      classes: {
        outer: field.cssClass,
      },
      validation: validationTypes(field.isRequired, field.type),
    })
  })
  schema.push({
    $el: 'div',
    children: message.value.replace(/<\/?[^>]+(>|$)/g, ''),
    attrs: {
      class: 'message-container',
    },
  })
  return schema
})

const getTheForm = () => (form.value = store.singleById(request.value))

const getForms = () => {
  return store.getSingleById(request.value).then(() => getTheForm())
}

const postData = async (URL, body) => {
  try {
    const response = await Axios.post(URL, body)
    message.value = response.data.confirmation_message
      ? response.data.confirmation_message
      : response.data.validation_messages[1]
    reset('formKit')
    disabled.value = false
  } catch (error) {
    console.error(error)
  }
}

const submitHandler = (data) => {
  // console.log(form.value)
  disabled.value = true
  let curedForm = {}
  for (const field of Object.entries(data)) {
    curedForm[
      `input_${
        form.value.fields.find((x) => {
          return x.label === field[0]
        }).id
      }`
    ] = field[1]
  }
  postData(form.value.submitURL, curedForm)
}

onBeforeMount(() => getForms())
</script>

<style lang="scss">
.contact-form {
  @include mobile {
    padding-bottom: 10px !important;
  }
  .contact-form-wrapper {
    @apply flex flex-col
    lg:flex-row lg:justify-between;

    .form-container,
    .contact-content {
      @apply lg:w-[49.5%];

      .message-container {
        @apply [background:linear-gradient(91deg,#67DAF4_6.58%,#E8BD50_99.51%)] bg-clip-text [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] 
          text-[24px] leading-[28px] tracking-[-0.528px] text-center
          mb-[36px] mt-[15px] px-[20px]
          lg:px-[60px];
      }
      .formkit-outer {
        .formkit-wrapper {
          @apply flex flex-col;
          .formkit-input[type='submit'] {
            @apply self-end;
          }
        }
      }
    }

    .form-container {
      padding: 40px 20px;
      background-color: $off-black;

      @media (max-width: 1024px) {
        margin-bottom: 6px;
      }

      .form-group-top {
        margin: 0 0 40px 15px;

        .form-section-title + .form-section-text {
          margin: 20px 0 0;
        }
      }
    }

    .contact-content {
      display: flex;
      flex-direction: column;
    }

    .jobs-callout {
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex: 1;
      padding: 60px 85px;
      background-image: url('@/assets/img/bg-careers-callout.svg');
      background-repeat: no-repeat;
      background-size: cover;
      color: $black;
      background-color: $white;

      @include mobile {
        order: 1;
        padding: 125px 31px 80px 31px;
      }

      .btn {
        margin: 50px 0 0;
      }

      .headline {
        @include mobile {
          font-size: 30px;
          font-style: normal;
          font-weight: 500;
          line-height: 34px;
          letter-spacing: -0.66px;
        }
      }
    }

    .concern-box {
      padding: 60px 85px;
      margin: 7px 0 0;
      color: $black;
      background-color: $light-blue;

      @include mobile {
        order: 0;
        padding: 49px 26px 36px 26px;
        margin: 0 0 7px;
      }
    }
  }
}
</style>

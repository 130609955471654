<template>
  <main class="single-team">
    <div class="container med">
      <template v-if="member">
        <div class="member-container">
          <div class="photo-container">
            <ResponsiveImage
              v-if="member.featured_media"
              :native="true"
              :media-id="member.featured_media" />
          </div>
          <div class="info-container">
            <div class="info-head">
              <h1
                class="name h5"
                v-html="member.title.rendered"></h1>
              <div
                v-if="member.acf.team_member_title"
                class="member-title"
                v-html="member.acf.team_member_title"></div>
            </div>
            <div
              v-if="member && member.content"
              class="member-bio editor-formatting"
              v-html="member.content.rendered"></div>
            <div
              v-if="member.acf.team_member_external_link"
              class="external">
              Read more:
              <a
                :href="member.acf.team_member_external_link"
                target="_blank"
                >here</a
              >
            </div>
          </div>
        </div>
      </template>
    </div>
  </main>
</template>

<script setup>
import { computed, ref, inject, onBeforeMount, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '../stores/main'
import useUtils from '@/components/composables/useUtils.js'
import ResponsiveImage from '@/components/utility/ResponsiveImage.vue'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/all'

gsap.registerPlugin(ScrollTrigger)

const navState = inject('navState')

const router = useRouter()
const store = useStore()
const props = defineProps({
  slug: {
    type: String,
    required: false,
    default: '',
  },
})

const { decodeTitle, device } = useUtils()

const request = ref({
  type: 'team',
  slug: props.slug,
  showLoading: true,
})

const member = computed(() => {
  return store.getCurrentSingleBySlug(request.value)
})

const getMember = async () => {
  await store.getSingleBySlug(request.value)
  if (member.value) {
    store.updateDocTitle({
      parts: [decodeTitle(member.value.title.rendered), store.site.name],
    })
  } else {
    router.replace('/404')
  }
}

const pin = () => {
  // console.log(device.value)
  if (device.value !== 'desktop') return
  ScrollTrigger.create({
    trigger: '.single-team',
    pin: '.responsive-image',
    pinSpacing: true,
    start: 'top top',
    end: 'bottom-=500 top',
  })
}

const animations = () => {
  gsap.fromTo(
    '.responsive-image',
    { opacity: 0, filter: 'blur(10px)' },
    { opacity: 1, filter: 'blur(0px)' }
  )
}

onBeforeMount(async () => {
  navState.value.class = 'compact'
  await getMember()
})

onMounted(() => {
  animations()
  pin()
})
</script>

<style lang="scss">
.single-team {
  padding: 150px 0 100px;
  background-image: url('@/assets/img/bg-team.svg');
  background-repeat: no-repeat;
  background-position: top left;
  color: $black;
  background-color: $white;

  @include mobile {
    background-position: top -265px left 130px;
  }

  .member-container {
    display: flex;
    gap: 40px;

    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
    }

    .photo-container {
      @apply max-w-[400px] lg:w-[42.5%];

      img {
        border-radius: 20px;
        overflow: hidden;
      }
    }

    .info-container {
      flex: 1;

      .info-head {
        margin: 0 0 60px;

        .name {
          margin: 0 0 20px;
        }
      }
    }
  }
}
</style>

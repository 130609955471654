<template>
  <article class="post">
    <a
      :href="post.acf.post_external ? post.acf.post_external : props.post.link"
      :title="props.post.post_title"
      :target="post.acf.post_external ? '_blank' : ''">
      <post-taxonomies
        v-if="false"
        :post="props.post" />
      <post-meta
        v-if="false"
        :post="props.post" />
      <div class="post-date h4">
        <time>{{ date }}</time>
      </div>
      <div
        v-if="props.post.acf.post_source"
        class="post-source"
        v-html="props.post.acf.post_source"></div>
      <p
        class="title"
        v-html="props.post.title.rendered"></p>
      <p class="btn blue">Read More</p>
    </a>
  </article>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { format } from 'date-fns'
import PostMeta from '@/components/utility/PostMeta.vue'
import PostTaxonomies from '@/components/utility/PostTaxonomies.vue'

const videoSrc = ref(false)

const props = defineProps({
  post: {
    type: Object,
    required: true,
  },
})
const date = ref(format(new Date(props.post.date), 'MMMM d, yyyy'))

const urlSrc = (src) => {
  try {
    const domain = new URL(src)

    if (domain.hostname == 'www.youtube.com') {
      videoSrc.value = true
    }
  } catch (error) {
    return false
  }
}

onMounted(() => {
  if (props.post.acf.post_external) {
    urlSrc(props.post.acf.post_external)
  }
})
</script>

<style lang="scss">
.post {
}
</style>

<template>
  <footer
    v-show="!store.site.loading && site"
    ref="trigger"
    class="site-footer">
    <div class="container large">
      <div class="footer-head">
        <div class="logo">
          <router-link
            :to="'/'"
            title="Home">
            <img
              ref="logo"
              :src="site.footer_logo.url"
              alt="Eikontx Logo" />
          </router-link>
        </div>
        <div class="locations">
          <div
            v-for="(location, index) in site.footer_locations"
            :key="index"
            class="location"
            v-html="location.footer_location"></div>
        </div>
      </div>
      <div class="footer-info">
        <div class="legal-copyright">
          <div class="copyright">
            <p>eikon therapeutics © {{ currentYear }}</p>
          </div>
          <footer-menu
            name="legal-menu"
            class="legal-menu" />
        </div>
        <div class="footer-boxes">
          <div class="socials">
            <a
              v-if="site.social_linkedin"
              :href="site.social_linkedin"
              target="_blank"
              class="social linkedin">
              <img
                src="@/assets/img/logo-linkedin.svg"
                alt="LinkedIn Logo" />
            </a>
            <a
              v-if="site.social_twitter"
              :href="site.social_twitter"
              target="_blank"
              class="social twitter">
              <img
                src="@/assets/img/logo-twitter.png"
                alt="Twitter Logo" />
            </a>
            <a
              v-if="site.social_facebook"
              :href="site.social_facebook"
              target="_blank"
              class="social facebook">
              <img
                src="@/assets/img/logo-facebook.svg"
                alt="Facebook Logo" />
            </a>
          </div>
          <div class="footer-contact">
            <div
              v-if="site.footer_number"
              class="footer-number font-larger">
              <a :href="`tel:${site.footer_number}`"
                >P:{{ site.footer_number }}</a
              >
            </div>
            <div
              v-if="site.footer_contact_email"
              class="footer-email font-larger">
              <a :href="`mailto:${site.footer_contact_email}`">{{
                site.footer_contact_email
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import FooterMenu from '@/components/template-parts/FooterMenu.vue'
import { useStore } from '@/stores/main'
import { ref, computed } from 'vue'

const store = useStore()
const site = ref(store.acfOptions())

const currentYear = computed(() => {
  return new Date().getFullYear()
})
</script>

<style lang="scss">
.site-footer {
  padding: 50px 0 40px;
  color: $black;
  background-color: $white;

  .footer-head {
    @apply flex-col lg:flex-row gap-10 mb-16 lg:mb-24;
    display: flex;
    justify-content: space-between;
    .locations {
      @apply flex-row;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      max-width: 650px;

      .location {
        width: 48%;
        margin: 0 0 30px;
        p {
          @include mobile {
            font-size: 16px;
          }
        }
      }
    }
  }

  .footer-info {
    @apply flex-col-reverse lg:flex-row gap-10;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .legal-copyright {
      @apply flex flex-col w-full md:w-1/2
      lg:flex-row;

      .copyright {
        margin-right: 70px;
      }

      .legal-menu {
        ul {
          display: flex;

          li {
            margin-right: 70px;

            &:last-child {
              margin: 0;
            }

            a {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .footer-boxes {
      @apply w-full md:w-1/2 justify-end;
      display: flex;

      .socials {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;
        width: 87px;
        height: 233px;
        padding: 30px 25px;
        margin-right: 7px;
        border-radius: 20px;
        background-color: $light-blue;
        a {
          height: 30px;
          width: 30px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .linkedin {
          padding: 1.5px;
        }
      }

      .footer-contact {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 386px;
        width: 100%;
        padding: 40px;
        border-radius: 20px;
        color: $white;
        background-color: $dark-purple;

        a {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>

import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'
import VueLazyLoad from 'vue3-lazyload'
import VueBodyClass from 'vue-body-class'
import VueScrollTo from 'vue-scrollto'
import { plugin, defaultConfig } from '@formkit/vue'
import { store } from './pinia'
import VueGtag from 'vue-gtag'

export const Application = createApp(App)
const routes = router.getRoutes()
const vueBodyClass = new VueBodyClass(routes)

Application.directive('scroll-to', VueScrollTo)
Application.use(router)
  .use(
    VueGtag,
    {
      pageTrackerTemplate(to) {
        return {
          page_title: printTitle(to),
          page_path: to.path,
        }
      },
      config: {
        id: 'G-26MKZW4YNE',
      },
    },
    router
  )
  .use(VueLazyLoad, {
    adapter: {
      loaded({ el }) {
        setTimeout(() => el.classList.add('loaded'), 100)
      },
    },
  })
  .use(plugin, defaultConfig)
  .use(store)
  .mount('#app')
router.beforeEach((to, from, next) => {
  vueBodyClass.guard(to, next)
})

const printTitle = (to) => {
  if (to.meta && to.meta.title) {
    return to.meta.title
  }
  return to.name
}

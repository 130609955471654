<template>
  <div class="modal platform-modal">
    <div
      class="modal-overlay"
      @click="closeModal"></div>
    <div class="modal-content">
      <div
        class="modal-close"
        :class="data.platform_color.value"
        @click="closeModal">
        <span></span>
        <span></span>
      </div>
      <div class="modal-copy border-radius">
        <div
          class="title h6"
          v-html="data.platform_title"></div>
        <div
          class="line"
          :class="data.platform_color.value"></div>
        <div
          class="text font-smaller editor-formatting"
          v-html="data.platform_text"></div>
      </div>
      <div
        class="modal-image border-radius"
        :style="{ backgroundImage: `url(${data.platform_image.link})` }" />
    </div>
  </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, inject } from 'vue'
import useSmoothScroll from '@/components/composables/useSmoothScroll.js'

defineProps(['data'])

const platformModal = inject('platformModal')
const { locoScroll } = useSmoothScroll()

const closeModal = () => {
  platformModal.value.open = false
  platformModal.value.data = null
}

const closeHandler = (e) => {
  if (e.keyCode === 27) {
    closeModal()
  }
}

onMounted(() => {
  document.body.classList.add('no-overflow')
  locoScroll.value.stop()
  document.addEventListener('keyup', closeHandler, { passive: true })
})

onBeforeUnmount(() => {
  document.body.classList.remove('no-overflow')
  locoScroll.value.start()
  document.removeEventListener('keyup', closeHandler, { passive: true })
})
</script>

<style lang="scss">
.platform-modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 100%;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(14.6px);
  z-index: 1000;

  .modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .modal-close {
    cursor: pointer;
    position: absolute;
    top: -35px;
    left: -35px;
    width: 35px;
    height: 35px;
    border-radius: 50%;

    &.orange {
      background: $orange;
      box-shadow:
        0px 0px 11.6px $orange,
        0px 0px 62.8px $orange;
    }

    &.purple {
      background: $purple;
      box-shadow:
        0px 0px 11.6px $purple,
        0px 0px 62.8px $purple;
    }

    &.pink {
      background: $pink;
      box-shadow:
        0px 0px 11.6px $pink,
        0px 0px 62.8px $pink;
    }

    @include mobile {
      top: -45px;
      left: 0px;
      width: 30px;
      height: 30px;
    }

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 15px;
      height: 3px;
      background-color: $black;

      &:first-child {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:last-child {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  .modal-content {
    @apply flex-col-reverse lg:flex-row gap-3 top-[4%] lg:top-0;
    position: relative;
    display: flex;
    width: 90%;
    max-width: 1000px;
    max-height: 90vh;
    z-index: 1;
    -webkit-overflow-scrolling: touch;

    .modal-copy {
      @apply w-full lg:w-3/5;
      margin-right: 10px;
      min-height: 340px;
      background-color: $off-black;

      .title {
        padding: 30px 35px 0;
        @include font-main;
      }

      .line {
        width: 100%;
        height: 1px;
        margin: 18px 0 50px;

        &.orange {
          background: linear-gradient(to right, $orange 0%, $purple 100%);
        }

        &.purple {
          background: linear-gradient(to right, $purple 0%, $pink 100%);
        }

        &.pink {
          background: linear-gradient(to right, $pink 0%, $white 100%);
        }
      }

      .text {
        padding: 0 35px 35px;
      }
    }

    .modal-image {
      @apply bg-cover w-full lg:w-2/5;
    }
  }
}
</style>

<template>
  <div class="pie-chart-component">
    <div class="pie-wrapper">
      <div class="pie-container-inner">
        <img
          class="pie-chart-inner"
          src="@/assets/img/pie-chart-inner.svg" />
      </div>
    </div>
    <div class="pie-wrapper">
      <div class="pie-container-outer">
        <img
          class="pie-chart-outer"
          src="@/assets/img/pie-chart-outer.png" />
      </div>
    </div>
  </div>
</template>

<script setup>
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { onMounted, onBeforeUnmount } from 'vue'

gsap.registerPlugin(ScrollTrigger)

defineProps(['data'])

const handleMultiLine = () => {
  var textBoxes = document.getElementsByClassName('multiline')
  for (var i = 0; i < textBoxes.length; i++) {
    createMultiline(textBoxes[i])
  }

  function createMultiline(textElement) {
    var words = textElement.firstChild.data.split(' ')
    var width = parseFloat(textElement.getAttributeNS(null, 'data-width'))

    // Clear original text
    textElement.firstChild.data = ''

    // Create first tspan element
    var tspanElement = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'tspan'
    )

    // Create text in tspan element
    var textNode = document.createTextNode(words[0])

    // Add tspan element to DOM
    tspanElement.appendChild(textNode)

    // Add text to tspan element
    textElement.appendChild(tspanElement)

    for (var i = 1; i < words.length; i++) {
      var len = textNode.data.length

      // Add next word
      tspanElement.firstChild.data += ' ' + words[i]

      if (tspanElement.getComputedTextLength() > width) {
        // Remove added word
        tspanElement.firstChild.data = tspanElement.firstChild.data.slice(
          0,
          len
        )

        // Create new tspan element
        tspanElement = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'tspan'
        )
        tspanElement.setAttributeNS(null, 'x', 0)
        tspanElement.setAttributeNS(null, 'dy', 28)

        textNode = document.createTextNode(words[i])
        tspanElement.appendChild(textNode)
        textElement.appendChild(tspanElement)
      }
    }
  }
}

const anim = () => {
  gsap.fromTo(
    '.pie-chart-outer',
    {
      transformOrigin: 'center',
      rotation: -90,
      scale: 1.025,
      x: -10,
      // y: -5,
    },
    {
      scrollTrigger: {
        trigger: '.pie-chart-component',
        id: 'arrowsRotation',
        start: 'top-=100% center',
        end: 'bottom+=100% center',
        scrub: true,
      },
      x: 0,
      y: 0,
      rotation: 0,
      duration: 3,
      scale: 1,
    }
  )
}

onMounted(() => {
  handleMultiLine()
  anim()
})

onBeforeUnmount(() => {
  const arrowsRotation = ScrollTrigger.getById('arrowsRotation')
  if (arrowsRotation) arrowsRotation.kill()
  const fullAparatusScale = ScrollTrigger.getById('fullAparatusScale')
  if (fullAparatusScale) fullAparatusScale.kill()
})
</script>
<style lang="scss">
.pie-chart-component {
  position: relative;
  width: 518px;
  height: 0;
  padding-bottom: 101%;
  & > * {
    @apply absolute;
  }

  @include tablet-landscape {
    padding-bottom: 105%;
  }

  .pie-wrapper {
    @apply h-[100%] w-[100%]
    flex justify-center items-center;
  }

  .pie-container-inner,
  .pie-container-outer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .pie-container-inner {
    top: 52%;
  }

  .pie-wrapper {
    .pie-container-inner {
      @apply w-[450px] h-[450px];

      @include tablet-landscape(1) {
        @apply w-[85%] h-[85%];
      }

      img {
        @apply w-[100%] h-[100%];
      }
    }

    .pie-container-outer {
      @apply w-[100%] h-[100%];

      img {
        position: relative;
        @apply w-[100%] h-[100%];
      }
    }
  }
}
</style>

<template>
  <div class="wrapper">
    <main class="main">
      <template v-if="page">
        <template v-if="page.template === 'page-legal.php'">
          <div class="page-legal-wrapper">
            <div class="container editor-formatting">
              <h1>{{ page.title.rendered }}</h1>
              <div
                class="legal-content"
                v-html="page.content.rendered"></div>
            </div>
          </div>
        </template>
        <template v-else>
          <template v-for="(module, index) in page.acf.modules">
            <template v-if="module.acf_fc_layout === 'HeroComponent'">
              <HeroComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template
              v-else-if="module.acf_fc_layout === 'GeneralContentComponent'">
              <GeneralContentComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'PipelineComponent'">
              <PipelineComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'TeamComponent'">
              <TeamComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'InvestorsComponent'">
              <InvestorsComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'MissionComponent'">
              <MissionComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template
              v-else-if="module.acf_fc_layout === 'ContactFormComponent'">
              <ContactFormComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template
              v-else-if="module.acf_fc_layout === 'FeaturedNewsComponent'">
              <FeaturedNewsComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template
              v-else-if="module.acf_fc_layout === 'ImgCarouselComponent'">
              <ImgCarouselComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'PieChartComponent'">
              <PieChartComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'PlatformComponent'">
              <PlatformComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template
              v-else-if="module.acf_fc_layout === 'GridCarouselComponent'">
              <GridCarouselComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'CareersComponent'">
              <CareersComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template
              v-else-if="module.acf_fc_layout === 'PositionDetailsComponent'">
              <PositionDetailsComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
          </template>
        </template>
      </template>
    </main>
  </div>
</template>

<script setup>
import { computed, inject, onBeforeMount, onMounted, nextTick } from 'vue'
import { useStore } from '@/stores/main'
import { useRouter } from 'vue-router'
import useUtils from '@/components/composables/useUtils.js'

// const HomepageHeroComponent = defineAsyncComponent(
//   async () =>
//     await import('@/components/modules/HomepageHeroComponent.vue').then((m) => {
//       return m
//     })
// )
import HeroComponent from '@/components/modules/HeroComponent.vue'
import GeneralContentComponent from '@/components/modules/GeneralContentComponent.vue'
import PipelineComponent from '@/components/modules/Pipeline/PipelineComponent.vue'
import TeamComponent from '@/components/modules/Team/TeamComponent.vue'
import InvestorsComponent from '@/components/modules/InvestorsComponent.vue'
import MissionComponent from '@/components/modules/MissionComponent.vue'
import ContactFormComponent from '@/components/modules/ContactFormComponent.vue'
import FeaturedNewsComponent from '@/components/modules/FeaturedNewsComponent.vue'
import ImgCarouselComponent from '@/components/modules/ImgCarouselComponent.vue'
import PieChartComponent from '@/components/modules/PieChartComponent.vue'
import PlatformComponent from '@/components/modules/PlatformComponent.vue'
import GridCarouselComponent from '@/components/modules/GridCarouselComponent.vue'
import CareersComponent from '@/components/modules/CareersComponent.vue'
import PositionDetailsComponent from '@/components/modules/PositionDetailsComponent.vue'

const store = useStore()
const router = useRouter()
const props = defineProps(['slug', 'isHomepage'])
const request = {
  type: 'pages',
  slug: props.slug,
  showLoading: true,
}

const { decodeTitle } = useUtils()

const navState = inject('navState')

const page = computed(() => {
  return store.getCurrentSingleBySlug(request)
})

onBeforeMount(() => getPage())

onMounted(() => {
  const anchor = router.currentRoute.hash
  nextTick(() => {
    if (anchor && document.querySelector(anchor)) {
      location.href = anchor
    }
  })
})

const getPage = async () => {
  store.getSingleBySlug(request).then(() => {
    if (page.value) {
      if (!props.isHomepage) {
        store.updateDocTitle({
          parts: [decodeTitle(page.value.title.rendered), store.site.name],
        })
      } else {
        navState.value.class = ''
        store.updateDocTitle({
          parts: [null, store.site.name],
        })
      }
    } else {
      router.replace('/404')
    }
  })
}
</script>

<style lang="scss">
body {
  overflow-x: hidden;
}
.page-legal-wrapper {
  padding: 250px 0 130px;
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply h5;
  }

  ul {
    @apply ml-[30px] flex flex-col gap-[0px];
    li {
      @apply pl-[30px] relative;
      &:before {
        @apply absolute left-0 top-[11px] content-[''] rounded-full h-[9px] w-[9px];
        box-shadow:
          0px 0px 11.6px $yellow,
          0px 0px 62.8px $yellow;
        background-color: $yellow;
      }
    }
  }

  table {
    @apply w-full;
    tr {
      td {
        @apply w-1/2 border border-solid border-[white]
          text-[22px];
      }
    }
  }
}
</style>

<template>
  <section
    id="news"
    class="featured-news module">
    <div class="container med">
      <div
        v-if="posts"
        class="featured-news-container">
        <div
          v-for="(post, index) in truncatedPosts"
          :key="index"
          class="featured-news border-radius">
          <div class="date-title">
            <time>{{ formatDate(post.date) }}</time>
            <div
              class="title h5"
              v-html="post.title.rendered"></div>
          </div>
          <a
            :href="post.acf.post_external ? post.acf.post_external : post.link"
            :target="post.acf.post_external ? '_blank' : ''"
            class="btn"
            >Read Article</a
          >
        </div>
      </div>
      <div
        v-if="!loadMore"
        class="load-more-container">
        <button
          class="btn"
          @click="loadMore = true">
          Load more
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "FeaturedNewsComponent",
    "CLASSNAME": "featured-news",
    "URL": "http://eikon.local/"
   }
  #ENDMETA */
import { useStore } from '@/stores/main'
import { onMounted, computed, ref } from 'vue'
import { format } from 'date-fns'

const store = useStore()
const props = defineProps(['data'])

const request = {
  type: 'posts',
  params: {
    include: props.data.featured_news,
    orderby: 'include',
    _fields:
      'id,slug,title,featured_media,link,categories,excerpt,date,modified,content,acf',
  },
  showLoading: false,
}

const loadMore = ref(false)

const formatDate = (date) => {
  return format(new Date(date), 'MM/d/yyyy')
}

const posts = computed(() => {
  return store.requestedItems(request)
})

const truncatedPosts = computed(() => {
  if (loadMore.value) {
    return posts.value
  }
  return posts.value.slice(0, 3)
})

const getPosts = () => {
  store.getItems(request)
}

onMounted(() => {
  getPosts()
})
</script>

<style lang="scss">
.featured-news {
  @include mobile {
    padding-top: 10px !important;
  }
  .featured-news-container {
    .featured-news {
      @apply flex flex-col items-start  mt-0 mb-[15px] mx-0
      px-[50px] py-[30px]
      lg:flex-row lg:items-center lg:justify-between;
      background-color: $off-black;

      @include mobile {
        padding: 35px 48px !important;
      }

      .title {
        @include mobile {
          @apply text-[24px] pb-[30px];
        }
      }

      &:last-child {
        margin: 0;
      }

      .date-title {
        flex: 1;
        margin-right: 30px;

        @include mobile {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: -0.352px;
        }

        time + .title {
          margin: 15px 0 0;
        }
      }
    }
  }
  .load-more-container {
    @apply flex justify-center mt-[25px] 
    lg:mt-[55px];
    button {
      @apply text-white;
      &:before {
        background: rgba(255, 255, 255, 0.2);
      }
    }
  }
}
</style>

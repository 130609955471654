<template>
  <div class="wrapper">
    <main>
      <article
        v-if="post"
        class="single-post">
        <div class="container">
          <div class="post-title">
            <h1
              class="title"
              v-html="post.title.rendered"></h1>
            <p>{{ date }}</p>
          </div>
          <div
            v-if="post.featured_image"
            class="featured-image mt-10">
            <img
              :src="post.featured_image.large"
              :alt="post.featured_image.alt" />
          </div>
          <div class="post-content">
            <template v-if="post.acf.post_external">
              <a :href="post.acf.post_external">Read More</a>
            </template>
            <template v-else>
              <div
                class="editor-formatting"
                v-html="post.content.rendered"></div>
            </template>
          </div>
        </div>
      </article>
    </main>
  </div>
</template>

<script setup>
import { ref, computed, onBeforeMount } from 'vue'
import { useStore } from '@/stores/main'
import { format } from 'date-fns'
import useUtils from '@/components/composables/useUtils.js'

const { decodeTitle } = useUtils()

const store = useStore()
const props = defineProps({
  slug: {
    type: String,
    required: false,
  },
  type: {
    type: String,
    required: false,
  },
})

const request = {
  type: 'posts',
  slug: props.slug,
  showLoading: true,
}

const date = ref('')
const post = computed(() => {
  return store.singleBySlug(request)
})

const getPost = async () => {
  store.getSingleBySlug(request)
  if (post.value) {
    store.updateDocTitle({
      parts: [decodeTitle(post.value.title.rendered), store.site.name],
    })

    date.value = format(new Date(post.value.date), 'MM/d/yyyy')
  }
}

onBeforeMount(() => getPost())
</script>

<style lang="scss">
.single-post {
  padding: 150px 0 100px;

  .post-title {
    margin: 0 0 40px;

    .title {
      margin: 0 0 20px;
    }
  }
  .post-content {
    .editor-formatting {
      h2,
      h3,
      h4,
      h5,
      h6 {
        @apply h5;
      }

      ul {
        @apply ml-[30px] flex flex-col gap-[0px];
        li {
          @apply pl-[30px] relative;
          &:before {
            @apply absolute left-0 top-[11px] content-[''] rounded-full h-[9px] w-[9px];
            box-shadow:
              0px 0px 11.6px $yellow,
              0px 0px 62.8px $yellow;
            background-color: $yellow;
          }
        }
      }

      table {
        @apply w-full;
        tr {
          td {
            @apply w-1/2 border border-solid border-[white]
          text-[22px];
          }
        }
      }
    }
  }
}
</style>

<template>
  <div
    ref="dropdown"
    class="dd-filter"
    :class="`theme-${theme}`">
    <div class="dd-filter-trigger">
      <span>
        {{ triggerText }}
      </span>
      <button
        class="arrow-btn"
        @click="switchDropdown">
        <svg
          class="arrow"
          :class="{ down: showDropdown }"
          width="18"
          height="11"
          viewBox="0 0 18 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            id="Vector 1"
            d="M1 9.77832L8.77817 2.00014L16.5563 9.77832"
            stroke="black"
            stroke-width="2" />
        </svg>
      </button>
      <button
        v-if="selected[0] != defaultVal"
        class="clear"
        @click="resetValues(e, true)">
        Clear
      </button>
    </div>
    <Vue3SlideUpDown v-model="showDropdown">
      <ul
        class="dd-filter-options"
        data-lenis-prevent>
        <li
          v-for="(option, idx) in options"
          :key="`filter-dd-${idx}`"
          class="option"
          :class="{
            ['active-filter']: selected.includes(option.name),
          }"
          @click="selectFilter(option, 0)">
          <div class="option-name">{{ option.name }}</div>
          <div class="radio" />
        </li>
      </ul>
    </Vue3SlideUpDown>
  </div>
</template>

<script setup>
import { Vue3SlideUpDown } from 'vue3-slide-up-down'
import { ref, watchEffect, onMounted, onBeforeMount } from 'vue'

const props = defineProps([
  'parentSelected',
  'options',
  'modelValue',
  'defaultVal',
  'theme',
  'triggerText',
])

const emit = defineEmits(['update:modelValue'])

const selected = ref(props.modelValue)
const dropdown = ref()

const showDropdown = ref(false)

const addValue = (value) => {
  let prevArr = selected.value
  let newArr = []
  if (prevArr.includes(value)) {
    newArr = prevArr.filter((item) => item !== value)
  } else {
    newArr = [...selected.value, value]
  }
  if (newArr.length === 0) {
    resetValues()
  } else {
    selected.value = newArr.filter((value) => value !== props.defaultVal)
    emit('update:modelValue', selected.value)
  }
}

const resetValues = (e, close) => {
  if (e) {
    e.stopPropagation()
  }
  selected.value = [props.defaultVal]
  emit('update:modelValue', selected.value)
  if (close) {
    showDropdown.value = false
  }
}

const switchDropdown = () => {
  showDropdown.value = !showDropdown.value
}
const selectFilter = (option) => {
  if (option.name === props.defaultVal) {
    resetValues()
  } else {
    addValue(option.name)
  }
}

watchEffect(() => {
  selected.value = props.parentSelected
})

const closeFiltersOnClick = (e, element) => {
  if (element) {
    if (!element.contains(e.target) && showDropdown.value) {
      showDropdown.value = false
    }
  }
}
onMounted(() => {
  document.body.addEventListener(
    'click',
    (event) => {
      closeFiltersOnClick(event, dropdown.value)
    },
    true
  )
})

onBeforeMount(() => {
  document.body.removeEventListener(
    'click',
    (event) => {
      closeFiltersOnClick(event, dropdown.value)
    },
    true
  )
})
</script>

<style lang="scss" scoped>
.dd-filter {
  @apply relative 
  lg:w-[350px] overflow-visible
  lg:h-[100px] bg-white rounded-[20px];
  color: $off-black;

  &-trigger {
    @apply bg-white  rounded-[20px] h-full
    flex items-center justify-between pl-[27px] pr-[36px] py-[30px];

    .arrow-btn {
      @apply z-5 bg-black w-[35px] h-[35px] flex items-center justify-center  rounded-full;

      .arrow {
        @apply rotate-180 transition-all ease-in-out;

        &.down {
          @apply rotate-0;
        }
      }
    }
    .clear {
      @apply text-white bg-black absolute top-[18px] left-[27px] z-5 flex justify-center items-center 
      w-[51px] h-[15px] 
      text-[10px] leading-[24px] tracking-[-0.22px] text-center rounded-[100px];
    }
  }

  &-options {
    @apply z-6 w-full bg-white flex flex-col max-h-[200px] overflow-y-scroll cursor-pointer rounded-bl-[20px] rounded-br-[20px]
    lg:absolute top-[82%];
    &::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 999px;
      background: rgba(0, 0, 0, 0.2);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.3);
    }
    .option {
      @apply flex justify-between items-center pl-[27px] pr-[36px]  py-[7.5px]  text-[14px] font-normal leading-[24px] tracking-[-0.308px]
      border-t border-solid border-t-[#E3E3E3];
      .radio {
        @apply relative rounded-full border w-[24px] h-[24px] border-solid border-[rgba(0,0,0,0.30)] transition-all;

        &::before {
          @apply transition-opacity opacity-0 absolute -translate-x-2/4 -translate-y-2/4 content-[''] 
          h-[16px] w-[16px] bg-black rounded-[100%] left-2/4 top-2/4;
        }
      }
      &.active-filter {
        .radio {
          &::before {
            @apply opacity-100;
          }
        }
      }
    }
  }

  &.theme-orange {
    .clear {
      @apply bg-[#F3883A];
      color: $off-black;
    }

    .arrow-btn {
      @apply bg-[#F3883A];
      box-shadow:
        0px 0px 62.8px #f3883a,
        0px 0px 11.6px #f3883a;
    }

    .radio {
      &::before {
        @apply bg-[#F3883A];
        box-shadow:
          0px 0px 62.8px #f3883a,
          0px 0px 11.6px #f3883a;
      }
    }
  }

  &.theme-purple {
    .clear {
      @apply bg-[#5E3BBC];
    }

    .arrow-btn {
      @apply bg-[#5e3bbc];
      box-shadow:
        0px 0px 62.8px #5e3bbc,
        0px 0px 11.6px #5e3bbc;
      .arrow {
        path {
          stroke: white;
        }
      }
    }

    .radio {
      &::before {
        @apply bg-[#5e3bbc];
        box-shadow:
          0px 0px 62.8px #5e3bbc,
          0px 0px 11.6px #5e3bbc;
      }
    }
  }
}
</style>

<template>
  <div class="carousel">
    <!-- Carousel Controls -->
    <div class="controls-container">
      <div class="controls">
        <button
          class="prev"
          @click="prev">
          <img src="@/assets/img/icon-arrow-left-white.svg" />
        </button>
        <div class="indicators">
          <button
            v-for="(el, idx) in items"
            :key="`idx-${idx}`"
            class="indicator"
            :class="{ active: idx === curr }"
            @click="selectItem(idx)">
            <div
              class="blur-circle"
              :class="colorIdx[idx]" />
          </button>
        </div>
        <button
          class="next"
          @click="next">
          <img src="@/assets/img/icon-arrow-right-white.svg" />
        </button>
      </div>
    </div>

    <!-- Slides Slot -->
    <div
      v-for="(element, idx) in items"
      :key="idx"
      class="carousel-slides">
      <transition
        tag="div"
        :css="false"
        @before-enter="(el) => beforeEnter(el, direction)"
        @enter="enter"
        @leave="(el, done) => leave(el, done, direction)">
        <slot
          v-if="idx === curr"
          name="slide"
          :slide-data="element"
          :slide-idx="idx"></slot>
      </transition>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import gsap from 'gsap'

// Props
const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  showCurr: {
    type: Boolean,
    required: false,
    default: false,
  },
})

// Reactive States
const curr = ref(0)
const direction = ref('right')
const colorIdx = ['light_blue', 'yellow', 'pink', 'purple'] // If still needed

// Watcher
watch(
  () => curr.value,
  (val, oldVal) => {
    direction.value = val > oldVal ? 'right' : 'left'
  }
)

// Methods
const prev = () => {
  if (curr.value !== 0) {
    curr.value -= 1
  } else {
    curr.value = props.items.length - 1
  }
}

const next = () => {
  if (curr.value !== props.items.length - 1) {
    curr.value += 1
  } else {
    curr.value = 0
  }
}

const selectItem = (idx) => {
  curr.value = idx
}

const beforeEnter = (el, dir) => {
  gsap.set(el, {
    opacity: 0,
    x: dir === 'left' ? -100 : 100,
  })
}

const enter = (el) => {
  const tl = gsap.timeline()
  tl.to(el, {
    opacity: 1,
    x: 0,
    duration: 0.2,
    ease: 'power2.inOut',
    // delay: 0.25,
  }).to(
    '.arrows',
    {
      pointerEvents: 'auto',
      delay: 0.2,
      duration: 0,
    },
    '<'
  )
}

const leave = (el, done, dir) => {
  const tl = gsap.timeline()
  tl.to(el, {
    opacity: 0,
    x: dir === 'left' ? 100 : -100,
    onComplete: done,
    duration: 0,
  }).to(
    '.arrows',
    {
      pointerEvents: 'none',
      duration: 0,
    },
    '<'
  )
}
</script>

<style lang="scss">
.carousel {
  @apply relative;
  .controls-container {
    @apply z-2 right-0 top-0 absolute flex justify-between  select-none
    px-[10px] pt-[10px]
    lg:top-0 lg:right-0 lg:px-[20px] lg:pt-[24px];
    .current {
      @apply flex items-center justify-center text-black text-[14px]
        w-[39px] h-[39px] shadow-[0px_0px_62.8px_#67DAF4] bg-[#67daf4] rounded-[50%]
        transition-all;
      &.light_blue {
        box-shadow:
          0px 0px 11.6px $light-blue,
          0px 0px 62.8px $light-blue;
        background-color: $light-blue;
      }
      &.yellow {
        box-shadow:
          0px 0px 11.6px $yellow,
          0px 0px 62.8px $yellow;
        background-color: $yellow;
      }
      &.purple {
        box-shadow:
          0px 0px 11.6px $purple,
          0px 0px 62.8px $purple;
        background-color: $purple;
      }
      &.pink {
        box-shadow:
          0px 0px 11.6px $pink,
          0px 0px 62.8px $pink;
        background-color: $pink;
      }
    }
    .controls {
      @apply flex pt-[6px]
      lg:pt-0;
      align-items: center;

      .prev,
      .next {
        @apply flex justify-center h-[50%] w-full   transition-all px-[10px];
        align-items: center;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        backdrop-filter: blur(14.6px);
        border-radius: 10px;

        &.disabled {
          @apply opacity-50 pointer-events-none;
        }
      }
      .next {
        @apply lg:py-0;
      }
      .prev {
        @apply lg:py-0;
      }
      .indicators {
        @apply flex gap-[20px] px-[20px] py-[19px] [background:rgba(0,0,0,0.60)] shrink-0 backdrop-blur-[14.600000381469727px] rounded-[10px]
        lg:gap-[10px];
        .indicator {
          @apply w-[16px]  flex flex-col justify-between items-center opacity-50 text-[16px] transition-all
          lg:w-[25px];
          &.active {
            @apply opacity-100 pointer-events-none;
            scale: 1.3;
          }

          &:hover {
            @apply opacity-100;
          }

          .blur-circle {
            display: block;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            box-shadow:
              0px 0px 11.6px transparent,
              0px 0px 62.8px transparent;
            background-color: transparent;
          }
          .light_blue {
            box-shadow:
              0px 0px 11.6px $light-blue,
              0px 0px 62.8px $light-blue;
            background-color: $light-blue;
          }
          .yellow {
            box-shadow:
              0px 0px 11.6px $yellow,
              0px 0px 62.8px $yellow;
            background-color: $yellow;
          }
          .purple {
            box-shadow:
              0px 0px 11.6px $purple,
              0px 0px 62.8px $purple;
            background-color: $purple;
          }
          .pink {
            box-shadow:
              0px 0px 11.6px $pink,
              0px 0px 62.8px $pink;
            background-color: $pink;
          }
        }
      }
    }
  }
}
</style>

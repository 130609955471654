<template>
  <section
    id="platform"
    class="platform module">
    <div class="container large">
      <div class="section-intro">
        <div
          v-if="data.platform_headline"
          class="headline h1"
          v-html="data.platform_headline"></div>
        <div class="cols">
          <div
            v-if="data.platform_col1_text"
            class="col editor-formatting"
            v-html="data.platform_col1_text"></div>
          <div
            v-if="data.platform_col2_text"
            class="col editor-formatting"
            v-html="data.platform_col2_text"></div>
        </div>
      </div>
      <div
        ref="trigger"
        class="platform-items">
        <div
          v-for="(item, index) in data.platform"
          :key="index"
          class="platform-item border-radius"
          :class="`platform-item-${index + 1}`"
          @click="openModal(item)">
          <div
            class="card-line"
            :class="item.platform_color.value"></div>
          <div class="btn-title">
            <p class="card-btn font-smaller">Learn More</p>
            <div
              class="title h5"
              v-html="item.platform_title"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "PlatformComponent",
    "CLASSNAME": "platform",
    "URL": "http://eikon.local/"
   }
  #ENDMETA */
import { gsap, Linear } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { ref, inject, onMounted, onBeforeUnmount } from 'vue'

gsap.registerPlugin(ScrollTrigger)

defineProps(['data'])

const trigger = ref(null)

let tl

const platformModal = inject('platformModal')

const openModal = (data) => {
  platformModal.value.open = true
  platformModal.value.data = data
}

onMounted(() => {
  tl = gsap.timeline({
    scrollTrigger: {
      trigger: trigger.value,
      start: 'top+=100 bottom',
      end: '+=400',
      scrub: true,
    },
  })

  tl.to('.platform-item-2', 1, {
    top: 0,
    ease: Linear.easeNone,
  }).to(
    '.platform-item-3',
    0.5,
    {
      top: 0,
      ease: Linear.easeNone,
    },
    0.5
  )
})

onBeforeUnmount(() => {
  tl.kill()
})
</script>

<style lang="scss">
.platform {
  @include mobile {
    padding-top: 40px !important;
  }

  .headline {
    @include mobile {
      margin-bottom: 30px !important;
    }
  }
  .platform-items {
    @apply flex flex-col gap-[12px] ml-auto mt-[50px]
    lg:flex-row lg:max-w-[1000px] lg:mt-[100px];

    .platform-item {
      @apply relative cursor-pointer flex flex-col justify-end  h-[276px]
      [background:rgba(100,100,100,0.2)] backdrop-blur-[14.6px] p-[30px]
      lg:flex-1 lg:h-[276px];

      &:nth-child(2) {
        top: 50px;
      }

      &:nth-child(3) {
        top: 100px;
      }

      &:last-child {
        margin: 0;
      }

      .card-line {
        position: absolute;
        display: flex;
        align-items: center;
        top: 30px;
        left: 30px;
        width: calc(100% - 30px);

        &.orange {
          &:before {
            background: $orange;
            box-shadow:
              0px 0px 11.6px $orange,
              0px 0px 62.8px $orange;
          }

          &:after {
            background: linear-gradient(to right, $orange 0%, $purple 100%);
          }
        }

        &.purple {
          &:before {
            background: $purple;
            box-shadow:
              0px 0px 11.6px $purple,
              0px 0px 62.8px $purple;
          }

          &:after {
            background: linear-gradient(to right, $purple 0%, $pink 100%);
          }
        }

        &.pink {
          &:before {
            background: $pink;
            box-shadow:
              0px 0px 11.6px $pink,
              0px 0px 62.8px $pink;
          }

          &:after {
            background: linear-gradient(to right, $pink 0%, $white 100%);
          }
        }

        &:before,
        &:after {
          content: '';
          display: block;
        }

        &:before {
          width: 21px;
          height: 21px;
          border-radius: 50%;
        }

        &:after {
          content: '';
          display: block;
          flex: 1;
          width: 100%;
          height: 1px;
        }
      }

      .card-btn {
        display: inline-block;
        padding: 6px 15px;
        margin: 0 0 20px;
        line-height: 1;
        background: rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(14.6px);
        border-radius: 100px;
      }
    }
  }
}
</style>

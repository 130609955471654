import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import emitter from '../scripts/emitter'
import { useStore } from '@/stores/main'

const { url } = window.__VUE_WORDPRESS__.routing
// scroll position is handled in @after-leave transition hook
if ('scrollRestoration' in window.history)
  window.history.scrollRestoration = 'manual'

export const router = createRouter({
  history: createWebHistory(url.replace(window.location.origin, '')),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // const position = { left: 0, top: 0 }
    // Only way I could see to go to an anchor on another page. Possible race condition.
    if (to.hash) {
      if (from.path === to.path) {
        // console.log('same route navigation')
        emitter.emit('scrollNow', {
          el: to.hash,
          offset: -90,
          duration: 1,
          lock: true,
          immediate: false,
        })
      }
      const navigate = () => {
        const store = useStore()
        if (store.site.loading) {
          setTimeout(() => {
            navigate()
          }, 300)
        } else {
          emitter.once('pageEnter', () => {
            // console.log('different route navigation')
            setTimeout(() => {
              emitter.emit('scrollNow', {
                el: to.hash,
                offset: -90,
                duration: 0,
                immediate: true,
              })
            }, 300)
          })
        }
      }
      navigate()
    } else {
      if (savedPosition) {
        return new Promise((resolve) => {
          // console.log('saved position')
          emitter.once('pageEnter', () => {
            if (savedPosition.top > 0) {
              emitter.emit('scrollNow', {
                el: savedPosition.top,
                offset: 0,
                duration: 0,
                immediate: true,
              })
            } else {
              emitter.emit('scrollNow', {
                el: 'top',
                offset: 0,
                duration: 0,
                immediate: true,
              })
            }
            resolve()
          })
        })
      } else {
        // console.log('no saved position')
        return new Promise((resolve) => {
          emitter.once('pageEnter', () => {
            emitter.emit('scrollNow', {
              el: 'top',
              offset: 0,
              duration: 0,
              immediate: true,
            })
            resolve()
          })
        })
      }
    }
  },
})

<template>
  <div class="team-section">
    <template v-if="team.length > 0">
      <div
        v-for="member in team"
        :key="member.id"
        class="team-member">
        <a
          :href="
            member.acf.team_member_external_link
              ? member.acf.team_member_external_link
              : member.link
          "
          :target="member.acf.team_member_external_link ? '_blank' : ''">
          <div class="image-container">
            <ResponsiveImage
              v-if="member.featured_media"
              :media-id="member.featured_media" />
            <div
              v-else
              class="image-placeholder"></div>
          </div>
          <div class="member-cta">
            <div class="member-info">
              <div class="name-title">
                <div
                  class="name"
                  v-html="member.title.rendered"></div>
                <div
                  class="title"
                  v-html="member.acf.team_member_title"></div>
              </div>
            </div>
            <div class="arrow"></div>
          </div>
        </a>
      </div>
    </template>
    <template v-else>
      <div
        v-for="(item, index) in data.team_list"
        :key="index"
        class="team-placeholder">
        <div class="placeholder-inner"></div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { useStore } from '@/stores/main'
import { ref, onMounted, computed } from 'vue'
import ResponsiveImage from '@/components/utility/ResponsiveImage.vue'

const store = useStore()
const props = defineProps(['data'])
const loading = ref(true)

const request = {
  type: 'team',
  params: {
    include: props.data.team_list,
    orderby: 'include',
    _fields: 'id,slug,link,title,featured_media,excerpt,acf,content',
    per_page: 100,
  },
  showLoading: false,
}

const team = computed(() => {
  return store.requestedItems(request)
})

const getTeam = () => {
  store.getItems(request).then(() => {
    loading.value = false
  })
}

onMounted(() => {
  getTeam()
})
</script>

<style lang="scss">
.team-section {
  display: flex;
  flex-wrap: wrap;

  .team-member {
    @apply w-full mr-0 lg:mr-[2%] mb-2 lg:mb-[45px] lg:w-[31%];

    a {
      @apply flex flex-row lg:flex-col gap-x-4;

      &:hover {
        .image-container {
          .responsive-image {
            img {
              transform: scale(1.05);
            }
          }
        }
      }

      .image-container {
        @apply w-[100px] h-[100px] lg:h-auto lg:w-full pb-0 lg:pb-[86%] justify-between items-center rounded-[10px] lg:rounded-[20px] overflow-hidden;
        position: relative;
        background-color: rgba(0, 0, 0, 0.2);

        .responsive-image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            min-height: 100%;
            object-fit: cover;
            object-position: center top;
            transform: scale(1);
            @include transition;
          }
        }
      }

      .member-cta {
        @apply flex items-center justify-between px-3 pt-0 lg:pt-6 w-full max-w-[70%] md:max-w-[85%] lg:max-w-full h-auto;
        .member-info {
          display: flex;
          justify-content: space-between;

          .name-title {
            @apply flex-1 lg:mr-[20px];
            @include font-main-med;
          }

          .name {
            @apply mb-3;
          }

          .title {
            @apply text-[15px] mt-[5px] 
            lg:m-0 lg:text-[18px];
          }
        }
        .arrow {
          @apply mt-1 lg:mt-0;
          display: flex;
          align-self: baseline;
          justify-content: center;
          align-items: center;
          width: 30px;
          min-width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: $black;

          &:after {
            content: '';
            display: block;
            width: 15px;
            height: 12px;
            background-image: url('@/assets/img/icon-arrow-right-white.svg');
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
      }
    }
  }

  .team-placeholder {
    @apply w-full mr-0 lg:mr-[2%] mb-2 lg:mb-[45px] lg:w-[31%];

    .placeholder-inner {
      height: auto;
      width: 100%;
      border-radius: 20px;
      padding-bottom: 86%;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}
</style>

<template>
  <section class="team-module module white">
    <div class="container">
      <div class="section-intro">
        <div
          class="headline h1"
          v-html="data.team_headline"></div>
        <div class="cols">
          <div
            class="col editor-formatting"
            v-html="data.team_col1_text"></div>
          <div
            class="col editor-formatting"
            v-html="data.team_col2_text"></div>
        </div>
      </div>
      <div
        id="leadership"
        class="team-filter">
        <div class="team-categories border-radius">
          <div
            v-for="(cat, index) in data.team_category"
            :key="index"
            class="category font-smaller"
            :class="{ active: index == activeTeam }"
            @click="updateFilter(index)">
            <p>{{ cat.team_category_name }}</p>
          </div>
        </div>
      </div>

      <div class="teams-container">
        <template
          v-for="(category, index) in data.team_category"
          :key="index">
          <Team
            v-if="index == activeTeam"
            :data="category" />
        </template>
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "TeamComponent",
    "CLASSNAME": "team-module",
    "URL": "http://eikon.local/"
   }
  #ENDMETA */
import Team from '@/components/modules/Team/Team.vue'
import { ref } from 'vue'

defineProps(['data'])

const activeTeam = ref(0)

const updateFilter = (idx) => {
  activeTeam.value = idx
}
</script>

<style lang="scss">
.team-module {
  @include mobile {
    padding-top: 20px !important;
  }
  .container {
    max-width: 1275px;
    @apply flex flex-col items-center
    lg:block;

    .headline {
      @include mobile {
        margin-bottom: 20px;
      }
    }
  }

  .team-filter {
    @apply flex mr-0 mt-0 mb-[70px] lg:ml-[8%] w-full lg:w-auto;
  }

  .team-categories {
    @apply w-full flex items-center justify-around lg:justify-center text-[white] bg-[rgba(0,0,0,0.7)] backdrop-blur-[14.6px] px-[25px] py-[36px]
    md:px-[80px]
    lg:w-auto;

    .category {
      cursor: pointer;
      position: relative;
      margin-right: 30px;

      &:last-child {
        margin: 0;
      }

      &.active:after {
        transform: translateX(-50%) scale(1);
      }

      &:after {
        content: '';
        position: absolute;
        bottom: -25px;
        left: 50%;
        display: block;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: #85def2;
        box-shadow:
          0px 0px 11.6px #67daf4,
          0px 0px 23.1px #67daf4;
        transform: translateX(-50%) scale(0);
        @include transition;
      }
    }
  }
}
</style>

<template>
  <section
    ref="trigger"
    class="hero">
    <div
      ref="bg"
      class="bg-video">
      <video
        v-if="data.hero_video"
        loop
        muted
        autoplay
        playsinline
        preload="auto">
        <source
          :src="data.hero_video.link"
          type="video/mp4" />
      </video>
    </div>
    <div class="container">
      <div class="hero-content">
        <div
          class="tagline font-larger"
          v-html="data.hero_tagline"></div>
        <div
          class="headline h1"
          v-html="data.hero_headline"></div>
      </div>
    </div>
    <div
      class="arrow-down"
      @click="scrollDown"></div>
    <div id="hero-bottom"></div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "HeroComponent",
    "CLASSNAME": "hero",
    "URL": "http://eikon.local/"
   }
  #ENDMETA */
import { gsap, Linear } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { ref, onMounted, onBeforeUnmount } from 'vue'
import locoScroll from '@/assets/js/LenisScroll.js'

gsap.registerPlugin(ScrollTrigger)

defineProps(['data'])

const trigger = ref(null)
const bg = ref(null)

let tl

const scrollDown = () => {
  locoScroll.value.scrollTo('#hero-bottom', {
    immediate: false,
  })
}

onMounted(() => {
  tl = gsap.timeline({
    scrollTrigger: {
      trigger: trigger.value,
      start: 'top',
      end: '+=300',
      scrub: true,
    },
  })

  tl.to(bg.value, {
    autoAlpha: 0,
    ease: Linear.easeNone,
  })
})

onBeforeUnmount(() => {
  tl.kill()
})
</script>

<style lang="scss">
.hero {
  position: relative;
  height: 100vh;
  min-height: 800px;
  padding-top: 250px;

  @include tablet-landscape {
    padding-top: 200px;
  }

  @include tablet-landscape(1) {
    padding-top: 175px;
  }

  @include mobile {
    height: auto;
    min-height: 0;
    padding-bottom: 150px;
  }

  .bg-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .hero-content {
    .tagline {
      margin: 0 0 40px;
    }

    .headline {
      @include font-main;
    }
  }

  .arrow-down {
    cursor: pointer;
    position: absolute;
    bottom: 40px;
    right: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 20px;
    background-color: $light-blue;

    @include mobile {
      bottom: 0;
      right: 10px;
    }

    &:after {
      content: '';
      display: block;
      width: 20px;
      height: 25px;
      background-image: url('@/assets/img/icon-arrow-down.svg');
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  #hero-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
</style>

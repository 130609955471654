<template>
  <div
    ref="intro"
    class="intro">
    <div class="intro-container">
      <div
        ref="animation"
        class="intro-animation"></div>
    </div>
  </div>
</template>

<script setup>
import LottieWeb from 'lottie-web'
// import introJson from '@/assets/lottie/intro.json'
import useIntroShown from '@/components/composables/useIntroShown.js'
import { ref, onMounted, onBeforeUnmount } from 'vue'

const { setIntroComplete } = useIntroShown()
const animation = ref()

onMounted(() => {
  // document.documentElement.classList.add('no-overflow')
  // const anim = LottieWeb.loadAnimation({
  //   container: animation.value,
  //   renderer: 'svg',
  //   autoplay: true,
  //   loop: false,
  //   animationData: introJson,
  // })
  // anim.onComplete = () => {
  //   setTimeout(() => {
  //     document.documentElement.classList.remove('no-overflow')
  //     setIntroComplete()
  //   }, 1000)
  // }

  setIntroComplete()
})

onBeforeUnmount(() => {
  LottieWeb.destroy
})
</script>

<style lang="scss"></style>
